import {Link} from "react-router-dom";
import React from "react";
import {authenticationService} from "../scripts/authentication.service";
import {Button, Tooltip} from "@mui/material";
import Home from '@mui/icons-material/Home';
import Persona from '@mui/icons-material/SupervisedUserCircle';
import Content from '@mui/icons-material/Apps';
import ContentItem from '@mui/icons-material/Label';
import ContentProfile from '@mui/icons-material/AccountBox';
import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack';
import Dupes from '@mui/icons-material/ContentCopy';
import Tags from '@mui/icons-material/LocalOffer';
import Logout from '@mui/icons-material/ExitToApp';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Settings from '@mui/icons-material/Settings';
import Upload from '@mui/icons-material/UploadFile';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import ImageIcon from '@mui/icons-material/Image';
import Snowfall from "react-snowfall";
import Breadcrumbs from "./components/breadcrumbs";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import QueueMusicIcon from '@mui/icons-material/QueueMusic';
import LanguageIcon from '@mui/icons-material/Language';
import {cf} from "../scripts/custom-fetch";
import Maintenance from "./components/maintenance";
import {searchService} from "../scripts/search.service";

class Header extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentUser: authenticationService.currentUserValue,
            isAPI: false,
            isDB: false,
            openPages: false,
            openTools: false,
            openBread: false,
            region: "X",
            settings: {
                id: 1,
                christmas: 0,
                easter: 0,
                halloween: 0,
                newyear: 0,
                online: 1,
            }
        };
    }

    getSettings = () => {
        const options = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json'},
        };

        cf.get(`${process.env.REACT_APP_API_GET}/settings`, options)
            .then(result => {
                this.setState({
                    region: result.region,
                    settings: result.settings
                })
            })
    }

    setClose() {
        localStorage.setItem('toClose', "true");
        let clearData = {
            page: 1,
            search: [],
            spin: [],
            type: [],
            tags: [],
        }
        searchService.setSearch(clearData)
        this.setState({
            openPages: false,
            openTools: false,
            openBread: false,
        })
    }

    testAPI() {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json'},
        };

        fetch(`${process.env.REACT_APP_API_GET}`, requestOptions)
            .then((result) => {
                    if (result.ok) {
                        this.setState({
                            isAPI: true,
                        });
                    } else {
                        this.setState({
                            isAPI: false,
                        });
                    }
                },
                (error) =>{
                    this.setState({
                        isAPI: false,
                    });
                }
            )
    }

    testDB() {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json'},
        };

        fetch(`${process.env.REACT_APP_API_GET}/dbTest`, requestOptions)
            .then((result) => {
                    if (result.ok) {
                        return result.text().then(text => {
                            let data = JSON.parse(text)
                            if (data[0].working) {
                                this.setState({
                                    isDB: true,
                                });
                            }
                        })
                    } else {
                        this.setState({
                            isDB: false
                        });
                    }
                },
                (error) =>{
                    this.setState({
                        isDB: false
                    });
                }
            )
    }

    open(type) {
        let openTools = this.state.openTools
        let openPages = this.state.openPages

        switch (type) {
            case 0:
                if (openTools) {
                    this.setState({
                        openTools: false,
                    })
                } else {
                    this.setState({
                        openTools: true,
                        openPages: false,
                    })
                }
                break;
            case 1:
                if (openPages) {
                    this.setState({
                        openPages: false,
                    })
                } else {
                    this.setState({
                        openPages: true,
                        openTools: false,
                    })
                }
                break;
            default:
                this.setState({
                    openPages: false,
                    openTools: false,
                })
                break;
        }
    }

    toggleBread = () => {
        let bread = this.state.openBread
        if (bread) {
            this.setState({openBread: false})
        } else {
            this.setState({openBread: true})
        }
    }

    componentDidMount() {
        authenticationService.currentUser.subscribe(x => this.setState({ currentUser: x }));

        try {
            let toClose = localStorage.getItem("toClose")
            if (toClose) {
                localStorage.removeItem('toClose');
                this.setState({
                    openPages: false,
                    openTools: false,
                    openBread: false,
                }, () => {
                    setTimeout(() => this.open(),500)
                })
            }
        } catch (e) {
            //pass
        }

        this.testAPI()
        this.testDB()
        this.getSettings()
    }

    logout(){
        authenticationService.logout(authenticationService.currentUserValue);
        window.location.reload();
    }

    render() {
        const {isAPI, region, settings, isDB, currentUser, openPages, openTools, openBread} = this.state;
        return (
            <div className="header">
                {settings.christmas ? <Snowfall style={{position: "fixed"}} /> : ""}
                {!currentUser.perms.dev && <Maintenance online={settings.online} reload={this.getSettings} />}
                <div className={openBread ? "breadcrumbs openBread" : "breadcrumbs"}>
                    <Breadcrumbs />
                    <Tooltip title="Breadcrumbs" placement="right" arrow>
                        <Button onClick={this.toggleBread}>
                            <ArrowForwardIosIcon />
                        </Button>
                    </Tooltip>
                </div>
                <div className="box">
                    <div>
                        <Link to="/">
                            <Tooltip title="Home" placement="right" arrow>
                                <Button>
                                    <Home />
                                </Button>
                            </Tooltip>
                        </Link>
                        <Link className={!(currentUser.perms.p1 || currentUser.perms.p2 || currentUser.perms.p3) && "hide"} to="/personas">
                            <Tooltip title="Personas" placement="right" arrow>
                                <Button onClick={() => this.setClose()}>
                                    <Persona />
                                </Button>
                            </Tooltip>
                        </Link>
                        <Tooltip className={!(currentUser.perms.c1 || currentUser.perms.c2 || currentUser.perms.c3) && "hide"} title="Content" placement="right" arrow>
                            <Button onClick={() => this.open(1)}>
                                <Content />
                            </Button>
                        </Tooltip>
                        <Tooltip title="Tools" placement="right" arrow>
                            <Button onClick={() => this.open(0)}>
                                <BuildCircleIcon />
                            </Button>
                        </Tooltip>
                        <Link className={!currentUser.perms.admin && "hide"} to="/admin">
                            <Tooltip title="Admin" placement="right" arrow>
                                <Button>
                                    <Settings />
                                </Button>
                            </Tooltip>
                        </Link>
                    </div>
                    <div>
                        <Link to="/profile">
                            <Tooltip title="Profile" placement="right" arrow>
                                <Button>
                                    <AccountCircleIcon />
                                </Button>
                            </Tooltip>
                        </Link>
                        <p style={{fontSize: "16px", fontWeight: 100, textShadow: "2px 2px 5px black"}}>{region}</p>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <div className={isAPI ? "on" : "off"} />
                            <p style={{fontSize: "16px", fontWeight: 100, textShadow: "2px 2px 5px black"}}>API</p>
                        </div>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <div className={isDB ? "on" : "off"} />
                            <p style={{fontSize: "16px", fontWeight: 100, textShadow: "2px 2px 5px black"}}>DB</p>
                        </div>
                        <Tooltip title="Logout" placement="right" arrow>
                            <Button onClick={this.logout}>
                                <Logout />
                            </Button>
                        </Tooltip>
                    </div>
                </div>
                <div className={openPages ? "content open" : "content closed"} >
                   <div className="open-container" style={{maxHeight: "78vh", overflowY: "auto", scrollbarWidth: "thin",  overflowX: "hidden", scrollbarColor: "rgb(245 0 87) var(--l-blue-dark2)"}}>
                     <Link to="/content/profile">
                        <Tooltip title="Profiles" placement="right" arrow>
                            <Button onClick={() => this.setClose()}>
                                <ContentProfile />
                            </Button>
                        </Tooltip>
                    </Link>
                    <Link to="/content/content">
                        <Tooltip title="Post Content" placement="right" arrow>
                            <Button onClick={() => this.setClose()}>
                                <ContentItem />
                            </Button>
                        </Tooltip>
                    </Link>
                    <Link to="/content/stim">
                        <Tooltip title="Post AD's" placement="right" arrow>
                            <Button onClick={() => this.setClose()}>
                                <ContentItem />
                            </Button>
                        </Tooltip>
                    </Link>
                    <Link to="/content/story">
                        <Tooltip title="Stories" placement="right" arrow>
                            <Button onClick={() => this.setClose()}>
                                <ContentItem />
                            </Button>
                        </Tooltip>
                    </Link>
                    <Link to="/content/watch">
                        <Tooltip title="Watch Content" placement="right" arrow>
                            <Button onClick={() => this.setClose()}>
                                <ContentItem />
                            </Button>
                        </Tooltip>
                    </Link>
                    <Link to="/content/watch_stim">
                        <Tooltip title="Watch AD's" placement="right" arrow>
                            <Button onClick={() => this.setClose()}>
                                <ContentItem />
                            </Button>
                        </Tooltip>
                    </Link>
                    <Link to="/content/reel">
                        <Tooltip title="Reel Content" placement="right" arrow>
                            <Button onClick={() => this.setClose()}>
                                <ContentItem />
                            </Button>
                        </Tooltip>
                    </Link>
                    <Link to="/content/reel_stim">
                        <Tooltip title="Reel AD's" placement="right" arrow>
                            <Button onClick={() => this.setClose()}>
                                <ContentItem />
                            </Button>
                        </Tooltip>
                    </Link>
                    <Link to="/content/gmail">
                        <Tooltip title="Gmail Content" placement="right" arrow>
                            <Button onClick={() => this.setClose()}>
                                <ContentItem />
                            </Button>
                        </Tooltip>
                    </Link>
                    <Link to="/musicCont">
                        <Tooltip title="Music Content" placement="right" arrow>
                            <Button onClick={() => this.setClose()}>
                                <MusicNoteIcon />
                            </Button>
                        </Tooltip>
                    </Link>
                    <Link to="/musicStim">
                        <Tooltip title="Music Stim Content" placement="right" arrow>
                            <Button onClick={() => this.setClose()}>
                                <MusicNoteIcon />
                            </Button>
                        </Tooltip>
                    </Link>
                    <Link to="/playlist">
                        <Tooltip title="Playlists" placement="right" arrow>
                            <Button onClick={() => this.setClose()}>
                                <QueueMusicIcon />
                            </Button>
                        </Tooltip>
                    </Link>
                    <Link to="/article">
                        <Tooltip title="Article Content" placement="right" arrow>
                            <Button onClick={() => this.setClose()}>
                                <Content />
                            </Button>
                        </Tooltip>
                    </Link>
                    <Link to="/content/postArticle">
                        <Tooltip title="Post Articles" placement="right" arrow>
                            <Button onClick={() => this.setClose()}>
                                <ContentProfile />
                            </Button>
                        </Tooltip>
                    </Link>
                    <Link to="/content/video">
                        <Tooltip title="Videos" placement="right" arrow>
                            <Button onClick={() => this.setClose()}>
                                <VideoCameraBackIcon />
                            </Button>
                        </Tooltip>
                    </Link>
                    <Link to="/content/video_stim">
                        <Tooltip title="Videos Stim" placement="right" arrow>
                            <Button onClick={() => this.setClose()}>
                                <VideoCameraBackIcon />
                            </Button>
                        </Tooltip>
                    </Link>
                   </div>
                </div>
                <div className={openTools ? "content open" : "content closed"} >
                    <Link className={currentUser.perms.dupes ? "" : "hide"} to="/dupes">
                        <Tooltip title="Dupes" placement="right" arrow>
                            <Button>
                                <Dupes />
                            </Button>
                        </Tooltip>
                    </Link>
                    <Link className={currentUser.perms.dupes ? "" : "hide"} to="/srcErrors">
                        <Tooltip title="Src Errors" placement="right" arrow>
                            <Button>
                                <ImageIcon />
                            </Button>
                        </Tooltip>
                    </Link>
                    <Link className={!(currentUser.perms.c1 || currentUser.perms.c2 || currentUser.perms.c3) && "hide"} to="/tags">
                        <Tooltip title="Tags" placement="right" arrow>
                            <Button>
                                <Tags />
                            </Button>
                        </Tooltip>
                    </Link>
                    <Link to="/content/import" className={!currentUser.perms.import && "hide"}>
                        <Tooltip title="Import CSV" placement="right" arrow>
                            <Button onClick={() => this.setClose()}>
                                <Upload />
                            </Button>
                        </Tooltip>
                    </Link>
                    {/* TODO: LANGUAGE PERMISSIONS */}
                    <Link to="/language" className={!currentUser.perms.import && "hide"}>
                        <Tooltip title="Translation Engine" placement="right" arrow>
                            <Button onClick={() => this.setClose()}>
                                <LanguageIcon />
                            </Button>
                        </Tooltip>
                    </Link>
                </div>
            </div>
        );
    }
}

export default Header;
