import React from "react";
import { authenticationService } from "../scripts/authentication.service";
import Header from "../header/header";
import {withSnackbar} from "notistack";
import {cf} from "../scripts/custom-fetch";
import {Button, LinearProgress, TableCell, Table, TableHead, TableBody, TableRow, Paper, TableContainer} from "@mui/material";
import {Link} from "react-router-dom";

class srcErrors extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUser: authenticationService.currentUserValue,
            errored: [],
            error: null,
            loaded: false,
        };
    }

    findErrors() {
        const options = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
        };

        cf.get(`${process.env.REACT_APP_API_GET}/images/findErrored`, options).then(result => {
            this.setState({
                errored: result,
                loaded: true
            })
        }, (error) =>{
            this.setState({
                error
            });
        })
    }

    componentDidMount() {
        this.findErrors()
    }

    render() {
        const { errored, error, loaded } = this.state;
        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!loaded) {
            return (
                <>
                    <Header/>
                    <div style={{width:"50%"}}>
                        <LinearProgress />
                    </div>
                </>
            )
        } else {
            return (
                <>
                    <Header />
                    <div className="dupes" style={{top: "-10rem", position: "relative"}}>
                        <div className="dupe-title">Issues</div>
                        <TableContainer className="dupe-container" style={{width: "80%", margin: "0 auto", maxHeight: "80vh"}}>
                            <Table component={Paper}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{fontWeight: "600"}}>Title</TableCell>
                                        <TableCell style={{fontWeight: "600"}}>ID</TableCell>
                                        <TableCell style={{fontWeight: "600"}}>Type</TableCell>
                                        <TableCell style={{fontWeight: "600"}}>Manage</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                     {errored.length > 0 ? errored.map(item => (
                                <TableRow>
                                    {/* {console.log(item)} */}
                                    <TableCell>{item.title ? item.title : item.name}</TableCell>
                                    <TableCell>{item.id}</TableCell>
                                    <TableCell>{item.type}</TableCell>
                                    <TableCell >
                                        <Link to={`/srcErrors/manage/${item.id}/${item.type}`}><Button size="small" variant="contained">Manage</Button></Link>
                                    </TableCell>
                                </TableRow>
                            )) : (
                            <TableRow>
                                <TableCell>No Src Disputes</TableCell>
                            </TableRow>
                            )}
                                </TableBody>
                            </Table>
                           
                        </TableContainer>
                    </div>
                </>
            );
        }
    }
}

export default withSnackbar(srcErrors);
