import React, { Component } from 'react';
import {Link} from "react-router-dom";
import Header from "../../header/header";
import {authenticationService} from "../../scripts/authentication.service";
import {Fab, Tooltip, TextField, Button, Pagination, InputAdornment, LinearProgress, TableContainer, Table, TableBody, TableRow, TableCell, TableHead, Paper, Checkbox} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import {cf} from "../../scripts/custom-fetch";
import {withSnackbar} from "notistack";
import Content from "../../api/content"
// import { source } from 'superagent';


class Profiles extends Component {
    
    constructor(props) {
        super(props);
        this.searchContent = this.searchContent.bind(this)
        this.state = {
            error: null,
            isLoaded: false,
            profiles: [],
            searchProfiles: [],
            restore: false,
            deletedItems: [],
            openDelete: false,
            deleteID: 0,
            currentPage: 1,
            itemsPerPage: 10,
            currentUser: authenticationService.currentUserValue,
            selectedRows: [],
            selectAll: false,
        };
    }
 
    
    callProfiles() {
        const options = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
        };

        cf.get(`${process.env.REACT_APP_API_GET}/db/getProfiles`, options)
            .then((result) => {
                this.setState({
                    isLoaded: true,
                    profiles: result,
                    searchProfiles: result
                });
            }, (error) =>{
                this.setState({
                    isLoaded: true,
                    error
                });
            })
    }

    openDelete = (item) => {
        this.setState({
            openDelete: true,
            deleteID: item
        })
    }

    closeDelete = () => {
        this.setState({
            openDelete: false
        })
    }

    delete = () => {
        let state
        let msg1
        let msg2
        if (this.state.restore) {
            msg1 = "Restoring"
            msg2 = "Restored"
            state = 1
        } else {
            msg1 = "Deleting"
            msg2 = "Deleted"
            state = 3
        }
        this.props.enqueueSnackbar(msg1, {variant:"info"});
        this.setState({
            openDelete: false,
        })
        Content.disable(11,state,this.state.deleteID).then(result => {
            this.callProfiles()
            this.setState({
                restore: false
            })
            console.log(result)
            this.props.enqueueSnackbar(msg2, {variant:"success"});
        })
    }

    openRestore = () => {
        Content.getDeletedItems(11).then(result => {
            if (result) {
                this.setState({
                    deletedItems: result,
                    restore: true
                })
            }
        })
    }

    closeRestore = () => {
        this.setState({
            restore: false
        })
    }


    componentDidMount() {
        this.callProfiles();
    }

    setPage(event, value) {
        this.setState({
            currentPage: value
        })
    }

    searchContent(event) {
        let results = this.state.profiles.filter(obj => {return obj.name.toUpperCase().includes(event.target.value.toUpperCase())})
        this.setState({
            searchProfiles: results
        })
    }

    handleSelectRow = (id) => {
        const {selectedRows} = this.state;
        const updatedSelectedRows = selectedRows.includes(id) ? selectedRows.filter(rowId => rowId !== id) : [...selectedRows, id];

        this.setState({
            selectedRows: updatedSelectedRows,
            selectAll: updatedSelectedRows.length === this.state.searchProfiles.length,
        });
    }

    handleSelectAll = (event) => {
        const {searchProfiles} = this.state;
        if (event.target.checked) {
            const allRowsIds = searchProfiles.map(item => item.id);
            this.setState({
                selectedRows: allRowsIds,
                selectAll: true
            });
        } else {
            this.setState({
                selectedRows: [],
                selectAll: false
            });
        }
    }

    deleteSelectedRows = () => {
        const {selectedRows} = this.state;
        const state = this.state.restore ? 1 : 3; // 1 for restore, 3 for delete
        this.props.enqueueSnackbar("Deleting selected profiles", {variant: "info"});

        Promise.all(
            selectedRows.map(id => Content.disable(11, state, id))
        ).then(() => {
            this.callProfiles();
            this.setState({
                selectedRows: [],
                selectAll: false
            });
            this.props.enqueueSnackbar("Selected profiles deleted", {variant: "success"});
        });
    };

    render() {
        const { error, isLoaded, currentPage, itemsPerPage, searchProfiles, openDelete, restore, deletedItems } = this.state;
        const indexOfLastPost = currentPage * itemsPerPage;
        const indexOfFirstPost = indexOfLastPost - itemsPerPage;
       

        let currentPosts
        if (restore) {
            currentPosts = deletedItems.slice(indexOfFirstPost, indexOfLastPost)
        } else {
            currentPosts = searchProfiles.slice(indexOfFirstPost, indexOfLastPost)
        }

        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return (
                <>
                    <Header/>
                    <div style={{width:"50%"}}>
                        <LinearProgress />
                    </div>
                </>
            )
        } else {
            return (
                <>
                    <Header/>

                    <div style={{position:"absolute",top:"0",left:"0",marginLeft:"50px",padding:"15px",backgroundColor:"rgba(0, 0, 0, 0.65)", display:"flex", borderRadius: "0 0 5px"}}>
                        <TextField
                            label="Search"
                            focused
                            style={{marginRight:"10px"}}
                            onChange={(e) => this.searchContent(e, 1)}
                            className="foreInputWhite"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon color="primary" />
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <Tooltip title="Add" aria-label="add">
                            <Fab color="primary" href={`/content/profile/edit/new`}>
                                <AddIcon />
                            </Fab>
                        </Tooltip>
                    </div>

                   

                    <TableContainer className="profiles-container table_container" style={{width:"60%", top: "2rem", maxHeight: "75vh"}}>
                        <Table component={Paper}>
                            <TableHead className='table_head'>
                                <TableRow >
                                <TableCell className="table_cell top_row" style={{width: "16%"}}>
                          <div style={{display: "flex"}}>
                            <div style={{display: "flex", flexDirection: "column"}}>
                              <span style={{fontWeight: "600"}}>Select All</span>
                            
                          
                            <Checkbox className="table_cell top_row" checked={this.state.selectAll} onChange={this.handleSelectAll}></Checkbox>
</div>
                          {this.state.selectedRows.length > 0 && (
                            <Button variant="contained" color="secondary" size="small" onClick={this.deleteSelectedRows} style={{width: "40%", height: "90%", top: "2.5rem"}}>DELETE SELECTED</Button>
                          )}
                          </div>
                          
                        </TableCell>
                                {/* <TableCell style={{width: "10%"}}></TableCell> */}
                                        <TableCell className="table_cell top_row" style={{fontWeight: 600, width: "30%"}}>Title</TableCell>
                                        <TableCell style={{width: "5%"}}></TableCell> 
                                </TableRow>
                            </TableHead>
                            <TableBody>
                               {currentPosts.map((item, index) => (
                            <TableRow key={item.id} className={index % 2 === 0 ? 'dark_row' : 'light_row'}> 
                            <TableCell style={{padding: "0.25rem 1.25rem"}}>
                                <Checkbox className="table_cell" checked={this.state.selectedRows.includes(item.id)} onChange={() => this.handleSelectRow(item.id)}></Checkbox>
                            </TableCell>
                            {/* <TableCell style={{padding: "0.25rem 1rem 0.25rem 1.5rem" }}>
                                        <CardMedia style={{height: "50px", width: "50px",}}
                                            component={item.pic.split(".")[item.pic.split(".").length - 1] === "mp4" ? "video" : "img" }
                                            image={item.pic}
                                            title={item.title}
                                        />
                                    </TableCell> */}
                                    <TableCell className="table_cell" style={{padding: "0.25rem 1rem 0.25rem 1rem" }}>
                                        <div className="hover-image" title={item.title} >
                                            {item.name}

                                            {item.pic ? (
                                                <div className="image-tooltip" style={{ backgroundImage: `url(${item.pic})`}} >
                                                   <img src={item.pic} alt={item.title} style={{ width: "100%", height: "100%" }} />
                                                </div>
                                            ) : (
                                                <div className="image-tooltip"></div>
                                            )}
                                        </div> 
                                    </TableCell>
                                <TableCell style={{padding: "0.25rem 1rem 0.25rem 1rem" }}>
                                    <Link to={`/content/profile/edit/${item.id}`}>
                                        <Button variant="contained" size="small" color="secondary">
                                            Edit
                                        </Button>
                                    </Link>
                                    {/* <Link>
                                        <Button variant="contained" size="small" color="secondary" onClick={() => this.openDelete(item.id)}>
                                            {restore ? "Restore" : "Delete"}
                                        </Button>
                                    </Link> */}
                                </TableCell>
                            </TableRow>
                        ))} 
                            </TableBody>
                        </Table>
                        
                    </TableContainer>

                    <div className={(openDelete ? 'user-box' : 'user-box hide')}>
                        <div className="user-container">
                            <p>Are you sure ?</p>
                            <div style={{display: "flex", marginTop: "10px"}}>
                                <Button variant="contained" color="primary" style={{flex: 1, marginRight: "10px"}} onClick={this.delete}>Yes</Button>
                                <Button variant="contained" color="primary" style={{flex: 1}} onClick={this.closeDelete}>Nope</Button>
                            </div>
                        </div>
                    </div>
                    <div className="pagination-container">
                        <Pagination color="secondary" count={Math.ceil(searchProfiles.length / itemsPerPage)} page={currentPage} onChange={(event, page) => this.setPage(event, page)} />
                    </div>
                    <div style={{position: "absolute", top: 0, right: 0, padding:"15px",backgroundColor:"rgba(0, 0, 0, 0.65)", borderRadius: "0 0 0 5px"}}>
                        {restore ? <Button variant="contained" color="primary" onClick={() => this.closeRestore()} className="spacer">Normal</Button> : <Button variant="contained" color="primary" onClick={() => this.openRestore()} className="spacer">Restore</Button>}
                        <Link to="/content"><Button style={{marginLeft: "10px"}} variant="contained" color="primary" className="spacer">Back</Button></Link>
                    </div>
                </>
            );
        }
    }
}

export default withSnackbar(Profiles);