import React from 'react';
import {Link} from "react-router-dom";
import Header from "../header/header";
import {authenticationService} from "../scripts/authentication.service";
import Items from "../api/content";
import {
    Table,
    TableCell,
    TableContainer,
    TableBody, TableHead, TableRow, Paper,
    Tooltip,
    Button,
    InputAdornment,
   
    TextField,
    LinearProgress,
    ToggleButton,
    ToggleButtonGroup,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {withSnackbar} from "notistack";
import F0F from "../errors/404";
import GppMaybeIcon from "@mui/icons-material/GppMaybe";
import {searchService} from "../scripts/search.service";

class Playlist extends React.Component {
    constructor(props) {
        super(props);

        this.searchContent = this.searchContent.bind(this)
        this.updateSearchText = this.updateSearchText.bind(this)

        this.state = {
            type: 1,
            should404: false,
            isStim: false,
            error: null,
            currentSearch: searchService.currentSearchValue.search,
            open: false,
            isLoaded: false,
            content: [],
            currentType: searchService.currentSearchValue.type,
            currentType3: searchService.currentSearchValue.type3,
            searchContent: [],
            playlist: {
                title: "",
                name: "",
                desc: "",
                src: "",
            },
            loadCount: 0,
            currentPage: searchService.currentSearchValue.page,
            itemsPerPage: 10,
            currentUser: authenticationService.currentUserValue,
        };
    }

    getContent(){
        Items.getAllPlaylists()
            .then(result => {
                this.setState({
                    content: result,
                    searchContent: result,
                    isLoaded: true
                }, () => {
                    this.searchContent()
                });
            })
    }

    newPlaylist = () => {
        Items.newPlaylist(this.state.playlist)
            .then(result => {
                console.log(result)
                this.getContent()
            })
    }

    componentDidMount() {
        this.getContent();
    }

    toggle = () => {
        this.setState({
            open: !this.state.open
        })
    }

    setPage(event, value) {
        let data = searchService.currentSearchValue
        data.page = value
        searchService.setSearch(data)
        this.setState({
            currentPage: value
        })
    }

    handleChange = (e) => {
        let playlist = this.state.playlist;
        playlist[e.target.name] = e.target.value;
        this.setState({playlist: playlist})
    }

    updateSearchText(event) {
        let data = searchService.currentSearchValue
        data.search = event.target.value
        searchService.setSearch(data)
        this.setState({
            currentSearch: event.target.value
        }, () => {
            this.searchContent()
        })
    }

    updateSearchType = (event) => {
        let data = searchService.currentSearchValue
        data.type = event.target.value
        searchService.setSearch(data)
        this.setState({
            currentType: event.target.value
        }, () => {
            this.searchContent()
        })
    }

    updateSearchType3 = (event) => {
        let data = searchService.currentSearchValue
        data.type3 = event.target.value
        searchService.setSearch(data)
        this.setState({
            currentType3: event.target.value
        }, () => {
            this.searchContent()
        })
    }

    searchContent() {
        let results = this.state.content

        if (this.state.currentSearch.length !== 0) {
            results = results.filter(obj => {return obj.title.toUpperCase().includes(this.state.currentSearch.toUpperCase())})
        }

        if (this.state.currentType === "new") {
            results = results.sort(function (a,b){
                return (b.id - a.id)
            })
        }

        if (this.state.currentType === "old") {
            results = results.sort(function (a,b){
                return (a.id - b.id)
            })
        }

        if (this.state.currentType3 === "sti") {
            results = results.filter(obj => {return obj.viewability_tracking})
        }

        if (this.state.currentType3 === "nsti") {
            results = results.filter(obj => {return !obj.viewability_tracking})
        }

        this.setState({searchContent:results})
    }

    render() {
        const { error, isLoaded, currentPage, itemsPerPage, currentSearch, searchContent, currentType, currentType3, should404, currentUser, playlist, open } = this.state;
        const indexOfLastPost = currentPage * itemsPerPage;
        const indexOfFirstPost = indexOfLastPost - itemsPerPage;
        const perms = currentUser.perms

        if (should404) {
            return <F0F />
        } else {
            let currentItems = searchContent.slice(indexOfFirstPost, indexOfLastPost)

            if (error) {
                return <div>Error: {error.message}</div>;
            } else if (!isLoaded) {
                return (
                    <>
                        <Header/>
                        <div style={{width:"50%"}}>
                            <LinearProgress />
                        </div>
                    </>
                )
            } else {
                return (
                    <>
                        <Header/>

                        <div style={{position:"absolute",top:"0",left:"0",marginLeft:"50px",padding:"15px",backgroundColor:"rgba(0, 0, 0, 0.65)", display:"flex", borderRadius: "0 0 5px"}}>
                            <TextField
                                label="Search"
                                style={{marginRight:"10px"}}
                                onChange={this.updateSearchText}
                                focused
                                value={currentSearch}
                                className="foreInputWhite"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon color="primary" />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <ToggleButtonGroup
                                color="primary"
                                value={currentType}
                                exclusive
                                onChange={this.updateSearchType}
                                style={{marginLeft: "10px", border: "#1565c0 2px solid"}}
                            >
                                <ToggleButton className="testing" value="new">New</ToggleButton>
                                <ToggleButton className="testing" value="old">Old</ToggleButton>
                            </ToggleButtonGroup>
                            <ToggleButtonGroup
                                color="primary"
                                value={currentType3}
                                exclusive
                                onChange={this.updateSearchType3}
                                style={{marginLeft: "10px", border: "#1565c0 2px solid"}}
                            >
                                <ToggleButton className="testing" value="all">All</ToggleButton>
                                <ToggleButton className="testing" value="sti">Stims</ToggleButton>
                                <ToggleButton className="testing" value="nsti">Non-Stims</ToggleButton>
                            </ToggleButtonGroup>
                        </div>

                        <TableContainer className="profiles-container" style={{width: "60%", top: "3rem"}}>
                            <Table component={Paper} className='table_container'>
                                <TableHead className='table_head'>
                                         <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell className="table_cell top_row" style={{fontWeight: 600}}>Title</TableCell>
                                        <TableCell className="table_cell top_row" style={{fontWeight: 600}}>Date</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {currentItems.map((item, index) => (
                                <TableRow key={item.id} className={index % 2 === 0 ? 'dark_row' : 'light_row'}>
                                    {item.status === 4 && (
                                        <div className="src-warning">
                                            <Tooltip title={"Source Error"}><GppMaybeIcon color="error" /></Tooltip>
                                        </div>
                                    )}
                                    <TableCell style={{padding: "0.25rem 1rem 0.25rem 1rem" }}>
                                            {/* {item.src ? (
                                                <CardMedia style={{height: "50px", width: "50px",}}
                                                    component={item.src.split(".")[item.src.split(".").length - 1] === "mp4" ? "video" : "img" }
                                                    image={item.src}
                                                    title={item.title}
                                                />
                                            ) : (
                                                <div className="table_cell" style={{fontWeight: 700, fontSize: "10px", width: "50px"}}>
                                                    WHERE'S THE LAMB SAUCE
                                                </div>
                                            ) } */}
                                    </TableCell>
                                        <TableCell className="table_cell">
                                            <div className="hover-image" title={item.title}>
                                            {item.title}
                                            {item.src ? (
                                                <div className="image-tooltip" style={{ backgroundImage: `url(${item.src})`}}></div> ) : (  <div className="image-tooltip" style={{fontWeight: 700, fontSize: "20px", background: "black", alignItems: "center", justifyContent: "center", padding: "20px"}}>
                                                    WHERE'S THE LAMB SAUCE
                                                </div>
                                            )}
                                            </div>
                                        </TableCell>
                                        <TableCell className="table_cell" >{new Date(item.date_created).getDay()}/{new Date(item.date_created).getMonth()}/{new Date(item.date_created).getFullYear()}</TableCell>
                                   
                                    <TableCell>
                                        <Link to={perms.c2 ? `/playlist/edit/${item.id}` : `/playlist`}>
                                            <Button disabled={!perms.c2} variant="contained" size="small" color="secondary">
                                                Edit
                                            </Button>
                                        </Link>
                                    </TableCell>
                                </TableRow>
                            ))}
                                </TableBody>
                            </Table>
                           
                        </TableContainer>
                       
                        <div className={(open ? 'user-box' : 'user-box hide')}>
                            <div className="user-container">
                                <TextField className="spacer" variant="outlined" label="Title" value={playlist.title} name="title" onChange={this.handleChange} />
                                <TextField style={{paddingTop:"10px"}} variant="outlined" label="Name" value={playlist.name} name="name" onChange={this.handleChange} />
                                <TextField style={{paddingTop:"10px"}} variant="outlined" label="Desc" value={playlist.desc} name="desc" onChange={this.handleChange} />
                                <TextField style={{paddingTop:"10px"}} variant="outlined" label="Pic" value={playlist.src} name="src" onChange={this.handleChange} />

                                <Button style={{marginTop:"10px"}} variant="contained" color="primary" onClick={() => this.newPlaylist()}>Submit</Button>
                                <Button style={{marginTop:"10px"}} variant="contained" color="primary" onClick={this.toggle}>Close</Button>
                            </div>
                        </div>
                        <div style={{position: "absolute", top: 0, right: 0, padding:"15px",backgroundColor:"rgba(0, 0, 0, 0.65)", borderRadius: "0 0 0 5px"}}>
                            <Button style={{marginLeft: "10px"}} variant="contained" color="primary" className="spacer" onClick={this.toggle}>New</Button>
                            <Link to="/"><Button style={{marginLeft: "10px"}} variant="contained" color="primary" className="spacer">Back</Button></Link>
                        </div>
                    </>
                );
            }
        }
    }
}

export default withSnackbar(Playlist);