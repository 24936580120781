import React from 'react';
import Header from "../header/header";
import {authenticationService} from "../scripts/authentication.service";
import {URL} from "../scripts/constants";
import {cf
} from "../scripts/custom-fetch";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Fab,
    Tooltip,
    Button,
    InputAdornment,
    TextField,
    LinearProgress, FormControlLabel, Switch
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from '@mui/icons-material/Edit';
import { ChromePicker } from 'react-color';

class Tags extends React.Component {
    constructor(props) {
        super(props);

        this.searchContent = this.searchContent.bind(this)
        this.handleChange = this.handleChange.bind(this);
        this.handleCheck = this.handleCheck.bind(this);

        this.state = {
            error: null,
            isLoaded: false,
            open: false,
            tags: [],
            edit: false,
            tag: {
                id: '',
                name: '',
                desc: '',
                color: '',
                important: 0,
            },
            searchTags: [],
            currentPage: 1,
            tagsPerPage: 30,
            currentUser: authenticationService.currentUserValue,
        };
    }

    handleChange(event) {
        let temp = this.state.tag;
        temp[event.target.name] = event.target.value;
        this.setState({tag: temp})
    }

    handleChangeComplete = (color) => {
        let temp = this.state.tag;
        temp.color = color.hex;
        this.setState({ tag: temp });
    }

    handleCheck(event) {
        let temp = this.state.tag;
        if (temp[event.target.name] === 1) {
            temp[event.target.name] = 0;
        } else {
            temp[event.target.name] = 1;
        }
        this.setState({ tag: temp });
    }

    callTags(){
        const options = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
        };
        cf.get( URL + "/tag/getTags", options)
            .then((result) => {
                    this.setState({
                        isLoaded: true,
                        tags: result,
                        searchTags: result
                    });
                },
                (error) =>{
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    newTag() {
        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
            body: JSON.stringify({
                'id':this.state.tag.id,
                'name':this.state.tag.name,
                'desc':this.state.tag.desc,
                'color':this.state.tag.color,
                'important':this.state.tag.important,
            })
        };
        if (this.state.edit) {
            cf.get(`${process.env.REACT_APP_API_POST}/tag/setTag`, options)
                .then((result) => {
                        console.log(result)
                        this.callTags()
                    },
                    (error) =>{
                        console.log(error)
                    }
                )
        } else {
            cf.get(`${process.env.REACT_APP_API_POST}/tag/newTag`, options)
                .then((result) => {
                        console.log(result)
                        this.callTags()
                    },
                    (error) =>{
                        console.log(error)
                    }
                )
        }
    }

    componentDidMount() {
        this.callTags();
    }

    setPage(event, value) {
        this.setState({
            currentPage: value
        })
    }

    close(e){
        e.preventDefault();
        this.setState({
            open: false,
            edit: false,
        })
    }

    open(){
        this.setState({
            open: true,
        })
    }

    openEdit(id) {
        let tag = this.state.tags.filter(item => (item.id === id))[0]
        this.setState({
            tag: tag,
            open: true,
            edit: true,
        })
    }

    searchContent(event) {
        let results = this.state.tags.filter(obj => {return obj.name.toUpperCase().includes(event.target.value.toUpperCase())})
        this.setState({
            searchTags: results
        })
    }

    render() {
        const { error, isLoaded, currentPage, tagsPerPage, searchTags, open, tag } = this.state;
        const indexOfLastPost = currentPage * tagsPerPage;
        const indexOfFirstPost = indexOfLastPost - tagsPerPage;
        const currentTags = searchTags.slice(indexOfFirstPost, indexOfLastPost)

        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return (
                <>
                    <Header/>
                    <div style={{width:"50%"}}>
                        <LinearProgress />
                    </div>
                </>
            )
        } else {
            return (
            <>
            <Header/>
                <div className="tag">
                    

                    <div className="search">
                        <TextField
                            label="Search"
                            variant="filled"
                            style={{marginRight:"10px"}}
                            onChange={this.searchContent}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Tooltip title="Add" aria-label="add">
                            <Fab color="primary" onClick={() => this.open()}>
                                <AddIcon />
                            </Fab>
                        </Tooltip>
                    </div>

                    <div className="profiles-container" style={{width: "90", maxHeight: "80vh", top: "3rem", paddingTop: "0"}}>
                <TableContainer component={Paper} className='table_container'>
                    <Table sx={{ minWidth: 650}} aria-label="simple table">
                        <TableHead className='table_head'>
                            <TableRow>
                                <TableCell className="table_cell top_row" style={{fontWeight: "600"}}>Title</TableCell>
                                <TableCell className="table_cell top_row" style={{fontWeight: "600"}}>Description</TableCell>
                                <TableCell className="table_cell top_row" style={{fontWeight: "600"}}>Color</TableCell>
                                <TableCell className="table_cell top_row" style={{fontWeight: "600"}}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {currentTags.map((item, index) => (
                            <TableRow key={item.id} className={index % 2 === 0 ? 'dark_row' : 'light_row'}>
                                    <TableCell className="table_cell">{item.name}</TableCell>
                                    <TableCell className="table_cell">{item.desc}</TableCell>
                                    <TableCell style={{ color: item.color }}>{item.color}</TableCell>
                                    <TableCell>
                                        <EditIcon color="primary" fontSize="large" onClick={() => this.openEdit(item.id)} style={{ cursor: 'pointer' }}/>
                                    </TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
</div>
                    {/* <div>
                        <Pagination count={searchTags.length / tagsPerPage} page={currentPage} variant="outlined" onChange={(event, page) => this.setPage(event, page)} />
                    </div> */}

                    <div className={(open ? 'user-box' : 'user-box hide')}>
                        <div className="user-container">
                            <TextField style={{paddingTop:"10px"}} variant="outlined" value={tag.name} label="Name" name="name" onChange={this.handleChange} />
                            <TextField style={{paddingTop:"10px"}} variant="outlined" value={tag.desc} label="Description" name="desc" onChange={this.handleChange} />
                            <div style={{alignSelf: "center", marginTop: "10px"}} >
                                <ChromePicker color={tag.color} onChangeComplete={ this.handleChangeComplete }/>
                            </div>
                            <FormControlLabel
                                className="spacer"
                                control={
                                    <Switch
                                        checked={tag.important}
                                        onChange={this.handleCheck}
                                        name="important"
                                        color="primary"
                                    />
                                }
                                label="Important"
                            />
                            <Button style={{marginTop:"10px"}} variant="contained" color="primary" onClick={() => this.newTag()}>Submit</Button>
                            <Button style={{marginTop:"10px"}} variant="contained" color="primary" onClick={e => this.close(e)}>Close</Button>
                        </div>
                    </div>
                </div>
            </>
            );
        }
    }
}

export default Tags;