import React, { useContext } from "react";
import { ThemeContext } from "../../ThemeContext";
import { Card, CardContent, FormControlLabel, Switch } from "@mui/material";
import EngineeringIcon from "@mui/icons-material/Engineering";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import AdbIcon from "@mui/icons-material/Adb";
import EggIcon from "@mui/icons-material/Egg";
import CelebrationIcon from "@mui/icons-material/Celebration";


const Settings = (props) => {
  
  const { theme} = useContext(ThemeContext);

  const cardStyles = {
    backgroundColor: theme === "light" ? "#f5f5f5" : "#424242",
    width: "200px",
    transition: "background-color 0.3s ease"
  };

  const cardContentStyles = {
    backgroundColor: theme === "light" ? "rgba(0, 0, 0, 0.23)" : "#515151",
    color: theme === "light" ? "rgb(52 50 50)" : "rgb(255, 255, 255)",
    transition: "background-color 0.3s ease, color 0.3s ease"
  };

  const formControlLabel = {
    backgroundColor: theme === "light" ? "rgb(133 131 131 / 23%)" : "rgba(71 70 70 / 23%)",
    color: theme === "light" ? "rgb(52 50 50)" : "rgb(255, 255, 255)",
    transition: "background-color 0.3s ease, color 0.3s ease",
    width: "100%",
    margin: "0 auto",
  };

  return (
    <div className="site-settings">
      <Card sx={cardStyles}>
        <CardContent style={cardContentStyles}>
          <EngineeringIcon sx={{ fontSize: 60 }} />
        </CardContent>
        <FormControlLabel style={formControlLabel}
          className="spacer"
          control={
            <Switch
              checked={!props.settings.online}
              onChange={props.handleCheck}
              name="online"
              color="primary"
            />
          }
          sx={{ padding: "10px", color: "#fff" }}
          label="Maintenance"
        />
      </Card>
      <Card sx={cardStyles}>
        <CardContent style={cardContentStyles}>
          <AcUnitIcon sx={{ fontSize: 60 }} />
        </CardContent>
        <FormControlLabel style={formControlLabel}
          className="spacer"
          control={
            <Switch
              checked={props.settings.christmas}
              onChange={props.handleCheck}
              name="christmas"
              color="primary"
            />
          }
          sx={{ padding: "10px", color: "#fff" }}
          label="Snow"
        />
      </Card>
      <Card sx={cardStyles}>
        <CardContent style={cardContentStyles}>
          <AdbIcon sx={{ fontSize: 60 }} />
        </CardContent>
        <FormControlLabel style={formControlLabel}
          className="spacer"
          control={
            <Switch
              checked={props.settings.halloween}
              onChange={props.handleCheck}
              name="halloween"
              color="primary"
            />
          }
          sx={{ padding: "10px", color: "#fff" }}
          label="Spooky"
        />
      </Card>
      <Card sx={cardStyles}>
        <CardContent style={cardContentStyles}>
          <EggIcon sx={{ fontSize: 60 }} />
        </CardContent>
        <FormControlLabel style={formControlLabel}
          className="spacer"
          control={
            <Switch
              checked={props.settings.easter}
              onChange={props.handleCheck}
              name="easter"
              color="primary"
            />
          }
          sx={{ padding: "10px", color: "#fff" }}
          label="Egg"
        />
      </Card>
      <Card sx={cardStyles}>
        <CardContent style={cardContentStyles}>
          <CelebrationIcon sx={{ fontSize: 60 }} />
        </CardContent>
        <FormControlLabel style={formControlLabel}
          className="spacer"
          control={
            <Switch
              checked={props.settings.newyear}
              onChange={props.handleCheck}
              name="newyear"
              color="primary"
            />
          }
          sx={{ padding: "10px", color: "#fff" }}
          label="New Year"
        />
      </Card>
    </div>
  );
};

export default Settings;
