import React from 'react';
import {Link} from "react-router-dom";
import Header from "../../header/header";
import {authenticationService} from "../../scripts/authentication.service";
import {Fab, Tooltip, TextField, Button, InputAdornment, LinearProgress, Table, TableContainer, TableRow, TableCell, Paper, TableHead, TableBody} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import {cf} from "../../scripts/custom-fetch";
import {withSnackbar} from "notistack";

class PostArticles extends React.Component {
    constructor(props) {
        super(props);
        this.searchContent = this.searchContent.bind(this)
        this.state = {
            error: null,
            isLoaded: false,
            articles: [],
            searchArticles: [],
            currentPage: 1,
            itemsPerPage: 10,
            currentUser: authenticationService.currentUserValue,
        };
    }

    callArticles() {
        const options = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
        };

        cf.get(`${process.env.REACT_APP_API_GET}/db/getPostArticles`, options)
            .then((result) => {
                this.setState({
                    isLoaded: true,
                    articles: result,
                    searchArticles: result
                });
            }, (error) =>{
                this.setState({
                    isLoaded: true,
                    error
                });
            })
    }

    componentDidMount() {
        this.callArticles();
    }

    setPage(event, value) {
        this.setState({
            currentPage: value
        })
    }

    searchContent(event) {
        let results = this.state.articles.filter(obj => {return obj.title.toUpperCase().includes(event.target.value.toUpperCase())})
        this.setState({
            searchArticles: results
        })
    }

    render() {
        const { error, isLoaded, currentPage, itemsPerPage, searchArticles } = this.state;
        const indexOfLastPost = currentPage * itemsPerPage;
        const indexOfFirstPost = indexOfLastPost - itemsPerPage;
        let currentPosts = searchArticles.slice(indexOfFirstPost, indexOfLastPost)

        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return (
                <>
                    <Header/>
                    <div style={{width:"50%"}}>
                        <LinearProgress />
                    </div>
                </>
            )
        } else {
            return (
                <>
                    <Header/>

                    <div style={{position:"absolute",top:"0",left:"0",marginLeft:"50px",padding:"15px",backgroundColor:"rgba(0, 0, 0, 0.65)", display:"flex", borderRadius: "0 0 5px"}}>
                        <TextField
                            label="Search"
                            focused
                            style={{marginRight:"10px"}}
                            onChange={(e) => this.searchContent(e, 1)}
                            className="foreInputWhite"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon color="primary" />
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <Tooltip title="Add" aria-label="add">
                            <Fab color="primary" href={`/content/postArticle/edit/new`}>
                                <AddIcon />
                            </Fab>
                        </Tooltip>
                    </div>

                    <TableContainer className="profiles-container table_container" style={{width:"40%", top: "-3rem"}}>
                        <Table component={Paper}>
                            <TableHead className='table_head'>
                                <TableRow>
                                {/* <TableCell  style={{width: "60px"}}></TableCell> */}
                                        <TableCell className="table_cell top_row" style={{fontWeight: 600}}>Title</TableCell>
                                        <TableCell style={{width: "100px"}}></TableCell> 
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                 {currentPosts.map((item, index) => (
                            <TableRow key={item.id} className={index % 2 === 0 ? 'dark_row' : 'light_row'}>
                                    {/* <TableCell  style={{padding: "0.25rem 1rem 0.25rem 1.5rem" }}>
                                        <CardMedia style={{height: "50px", width: "50px",}}
                                            component={item.logo.split(".")[item.logo.split(".").length - 1] === "mp4" ? "video" : "img" }
                                            image={item.logo}
                                            title={item.title}
                                        />
                                    </TableCell> */}
                                    <TableCell className="table_cell">
                                        <div className="hover-image" title={item.title}>
                                            {item.title}

                                            {item.logo ? (
                                                item.logo.split(".").pop() === "mp4" ? (
<div className="image-tooltip video-container">
    <video style={{width: "100%", height: "100%"}}>
        <source src={item.logo} type="video/mp4"/>
    </video>
</div>
                                                ) : (
<div className="image-tooltip" style={{ backgroundImage: `url(${item.logo})`}}></div> )
                                                ) : (
                                                    <div className="image-tooltip"></div>
                                                )}
                                          
                                        </div>

                                    </TableCell>
                                <TableCell>
                                    <Link to={`/content/postArticle/edit/${item.id}`}>
                                        <Button variant="contained" size="small" color="secondary">
                                            Edit
                                        </Button>
                                    </Link>
                                </TableCell>
                            </TableRow>
                        ))}
                            </TableBody>
                        </Table>
                       
                    </TableContainer>

                   
                    <div style={{position: "absolute", top: 0, right: 0, padding:"15px",backgroundColor:"rgba(0, 0, 0, 0.65)", borderRadius: "0 0 0 5px"}}>
                        <Link to="/content"><Button style={{marginLeft: "10px"}} variant="contained" color="primary" className="spacer">Back</Button></Link>
                    </div>
                </>
            );
        }
    }
}

export default withSnackbar(PostArticles);
