import React from "react";
import { Button, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Collapse, Box, Typography, Tooltip } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

class users extends React.Component {
    render() {

        return (
            <div className="items" style={{width: "70%", margin: "0 auto", paddingTop: "0"}}>
                <TableContainer className="table_container" component={Paper} style={{maxHeight: "85vh"}}>
                    <Table aria-label="collapsible table" style={{margin: "0 auto"}}>
                   <TableHead className="table_head">
                        <TableRow>
                            <TableCell style={{width:"10%"}}/>
                            <TableCell className="table_cell top_row" style={{width:"10%",fontWeight: "600"}}>ID</TableCell>
                            <TableCell className="table_cell top_row" style={{width:"40%",fontWeight: "600"}}>Name</TableCell>
                            <TableCell className="table_cell top_row" style={{width:"20%",fontWeight: "600"}}>Actions</TableCell>
                            <TableCell className="table_cell top_row" align="right" style={{width:"20%",fontWeight: "600"}}>Permissions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {this.props.items.map((item, index) => (

                        this.props.page !== item.enabled && (
                            <UserRow key={item.id} item={item} index={index} {...this.props} />
                        )
                    ))}
                    </TableBody>      
                    </Table>
                   
                </TableContainer>
            </div>
        );
    }
}

class UserRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {open: false};
    }

    handleToggle = () => {
        this.setState({open: !this.state.open});
    };

    render() {
        const { item, index } = this.props;
        const { open } = this.state;

        return (
            <>
            <TableRow className={`table_row ${index % 2 === 0 ? 'dark_row' : 'light_row'}`}>
                <TableCell style={{borderBottom: "none"}}>
                <Tooltip title={<span>Click to view permissions</span>}>
                    <IconButton aria-label="expand row" size="small" onClick={this.handleToggle}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon/>}
                        </IconButton>
                        </Tooltip>     
                </TableCell>
                <TableCell className="table_cell" component="th" scope="row" style={{borderBottom: "none"}}>{item.id}</TableCell>
                <TableCell className="table_cell" style={{borderBottom: "none"}}>
               {item.username}
                </TableCell>
                <TableCell style={{justifyContent: "space-between", display: "flex",  borderBottom: "none"}}>
                    <Button size="small" variant="contained" color="secondary" onClick={() => this.props.openEdit(item.username)} style={{width: "77.5px"}} >Edit</Button>
                    {item.enabled ? (
                                        <Button onClick={() => this.props.disableUser(item.username)} size="small" variant="contained" color="secondary">
                                            Disable
                                        </Button>
                                    ) : (
                                        <Button onClick={() => this.props.enableUser(item.username)} size="small" variant="contained" color="secondary">
                                            Enable
                                        </Button>
                                    )}
                </TableCell>
                <TableCell  className="table_cell" align="right" style={{borderBottom: "none"}}>
                    
                    <span>{item.admin ? 'Admin' : 'User'}</span>
                    
                </TableCell>
            </TableRow>
            <TableRow className="permissions_container">
                <TableCell style={{ paddingBottom: 0, paddingTop: 0}} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountedOnExit>
                    <Box sx={{margin: 1}}>
                        <Typography variant="h6" gutterBottom component="div" style={{color: "#9c27b0", textDecorationLine: "underline"}}>
                            Permissions
                        </Typography>
                        <ul style={{ listStyleType: "none"}}>
                                    {item.dev ? <li>Developer</li> : <li> </li>}
                                    {item.admin ? <li>Admin</li> : <li> </li> }
                                    {(item.c1 || item.c2 || item.c3) ? <li>Content</li> : <li> </li> }
                                    {item.import ? <li>Importer</li> : <li> </li>}
                                    {(item.p1 || item.p2 || item.p3) ? <li>Persona</li> : <li> </li> }
                                    {item.dupes ? <li>Duplicate Manager</li> : <li> </li> }
                        </ul>
                    </Box>
                    </Collapse>
                    </TableCell>
            </TableRow>
            </>
        );
    }
}
               
//                     <>
//                     {this.props.page !== item.enabled && (
//                         <Card  style={{width:"340px", marginLeft:"27px" , marginBottom:"20px", backgroundColor: "#424242", display: "flex"}}>
//                             <div style={{position: "relative", textAlign: "left", height: "140px", width: "240px"}}>
//                                 {item.admin ? (<div className="master">Admin</div>) : ""}
//                                 <CardContent style={{backgroundColor: "#515151", color: "#fff", height: "calc(100% - 46px)"}}>
//                                     <div style={{fontSize: "18px"}}>ID: {item.id}</div>
//                                     <Tooltip title={
//                                         <React.Fragment>
//                                             <div style={{fontSize: "16px"}}>{item.username}</div>
//                                         </React.Fragment>
//                                     } aria-label="add">
//                                         <div style={{fontSize: "18px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}}>Name: {item.username}</div>
//                                     </Tooltip>
//                                 </CardContent>
//                                 <CardActions style={{display: "flex", justifyContent: "space-between"}}>
//                                     <Button size="small" variant="contained" color="secondary" onClick={() => this.props.openEdit(item.username)}>
//                                         Edit
//                                     </Button>
                                   
//                                 </CardActions>
//                             </div>
//                             <Tooltip title={
//                                 <React.Fragment>
//                                     <div style={{fontSize: "16px"}}>User Perms</div>
                                   
//                                 </React.Fragment>
//                             } aria-label="add">
//                                 <CardMedia image={item.pic} title="pic" style={{width:"100px", height: "100%"}}/>
//                             </Tooltip>
//                         </Card>
//                         )}
//                     </>
//                 ))}
//             </div>
//         );
//     }
// }

export default users;