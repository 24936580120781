import React from 'react';
import Header from "../header/header";
import {authenticationService} from "../scripts/authentication.service";
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import {Link} from "react-router-dom";
import Content from "../api/content"
import Stims from "../api/stims"
import {
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    Button,
    Switch,
    TextField,
    Pagination,
    InputAdornment,
    Autocomplete,
    ToggleButtonGroup,
    ToggleButton,
    LinearProgress,
    Modal, Tooltip,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Tags from "../content/components/tags";
import {withSnackbar} from "notistack";
import {cf} from "../scripts/custom-fetch";
import ContentShow from "./components/content"
import Story from "./components/story";
import Watch from "./components/watch";
import WatchAD from "./components/watchAD";
import Edit from "../content/edit";
import GmailEdit from "../content/components/gmailEdit";

class PersonaEdit extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            id: this.props.match.params.id,
            editID: 1,
            content: [],
            stims: [],
            watch: [],
            watchADs: [],
            allContent: [],
            allStims: [],
            allStories: [],
            allWatch: [],
            allWatchADs: [],
            allReels: [],
            allReelADs: [],
            allLanguageCodes: [],
            reels: [],
            reelADs: [],
            stories: [],
            profile: [],
            persona: [],
            currentFeed: [],
            tags: [],
            linkTags: [],
            currentTags: [],
            currentSearch: [],
            errors:{},
            currentType: "all",
            currentType2: "",
            currentType3: "all",
            currentPage: 1,
            itemsPerPage: 10,
            searchContent: [],
            lang: [],
            countries: [],
            openModal: false,
            openView: false,
            openReelView: false,
            openVideoView: false,
            posting: false,
            loadNeeded: 6,
            isLoaded: false,
            loadCount: 0,
            error: null,
            open: false,
            openPrompts: false,
            page: 0
        };
    }

    setPages(event, value) {
        this.setState({
            currentPage: value
        })
    }

    setPage(num, refresh){
        this.setState({
            page: num
        })

        let toPage = 1
        if (refresh) {
            toPage = this.state.currentPage
        }

        switch (num){
            case 0:
                this.setState({
                    searchContent: this.state.allContent,
                    currentFeed: this.state.content.sort(function(a, b){return a.order - b.order}),
                    currentPage: toPage
                })
                break;

            case 1:
                this.setState({
                    searchContent: this.state.allStims,
                    currentFeed: this.state.stims.sort(function(a, b){return a.order - b.order}),
                    currentPage: toPage
                })
                break;

            case 2:
                this.setState({
                    searchContent: this.state.allStories,
                    currentFeed: this.state.stories.sort(function(a, b){return a.order - b.order}),
                    currentPage: toPage
                })
                break;

            case 3:
                this.setState({
                    searchContent: this.state.allWatch,
                    currentFeed: this.state.watch.sort(function(a, b){return a.order - b.order}),
                    currentPage: toPage
                })
                break;

            case 4:
                this.setState({
                    searchContent: this.state.allWatchADs,
                    currentFeed: this.state.watchADs.sort(function(a, b){return a.order - b.order}),
                    currentPage: toPage
                })
                break;

            case 5:
                this.setState({
                    searchContent: this.state.allReels,
                    currentFeed: this.state.reels.sort(function(a, b){return a.order - b.order}),
                    currentPage: toPage
                })
                break;

            case 6:
                this.setState({
                    searchContent: this.state.allReelADs,
                    currentFeed: this.state.reelADs.sort(function(a, b){return a.order - b.order}),
                    currentPage: toPage
                })
                break;

            default:
                break;
        }
    }

    open(){
        this.setState({
            open: true,
        })
    }

    close(e){
        e.preventDefault();
        this.setState({
            open: false,
            openPrompts: false,
        })
    }

    toggleModal = () => {
        let temp = this.state.openModal
        if (temp) {
            this.setState({
                openModal: false
            })
        } else {
            this.setState({
                openModal: true
            })
        }
    }

    openPrompts(){
        this.setState({
            openPrompts: true,
        })
    }

    getPersona(refresh) {
        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
            body: JSON.stringify({'id':this.state.id, 'refresh': refresh})
        };

        cf.get(`${process.env.REACT_APP_API_GET}/db/getPersona`, options)
            .then((result) => {
                if (!result.persona.language_code) {
                    result.persona.language_code = 1;
                }
                if (refresh) {
                    this.setState({
                        persona: result.persona,
                        content: result.content,
                        stims: result.stims,
                        stories: result.stories,
                        watch: result.watch,
                        watchADs: result.watchStims,
                        reels: result.reels,
                        reelADs: result.reelADs,
                    }, () => {this.setPage(this.state.page, true)});
                } else {
                    this.setState({
                        persona: result.persona,
                        lang: result.lang,
                        countries: result.countries,
                        linkTags: result.linkTags,
                        tags: result.tags,
                        content: result.content,
                        allContent: result.allContent,
                        stims: result.stims,
                        allStims: result.allStims,
                        stories: result.stories,
                        allStories: result.allStories,
                        watch: result.watch,
                        allWatch: result.allWatch,
                        watchADs: result.watchStims,
                        allWatchADs: result.allWatchStims,
                        reels: result.reels,
                        allReels: result.allReels,
                        reelADs: result.reelADs,
                        allReelADs: result.allReelADs,
                        allLanguageCodes: result.allLanguageCodes,
                        isLoaded: true,
                    }, () => {this.setPage(0, false)});
                }},
                (error) =>{
                    this.setState({
                        error
                    });
                }
            )
    }

    link(id) {
        let order
        if (this.state.page === 0) {
            !!this.state.content.length > 0 ? order = (this.state.content.at(-1).order + 1) : order = 1
        } else if (this.state.page === 1) {
            !!this.state.stims.length > 0 ? order = (this.state.stims.at(-1).order + 3) : order = 3
        } else if (this.state.page === 2) {
            !!this.state.stories.length > 0 ? order = (this.state.stories.at(-1).order + 1) : order = 1
        } else if (this.state.page === 3) {
            !!this.state.watch.length > 0 ? order = (this.state.watch.at(-1).order + 1) : order = 1
        } else if (this.state.page === 4) {
            !!this.state.watchADs.length > 0 ? order = (this.state.watchADs.at(-1).order + 3) : order = 3
        } else if (this.state.page === 5) {
            !!this.state.reels.length > 0 ? order = (this.state.reels.at(-1).order + 1) : order = 1
        } else if (this.state.page === 6) {
            !!this.state.reelADs.length > 0 ? order = (this.state.reelADs.at(-1).order + 1) : order = 1
        }

        let API
        switch (this.state.persona.type) {
            case 1:
                API = 1
                break;
            case 2:
                API = 6
                break;
            case 4:
                API = 12
                break;
            case 5:
                API = 13
                break;
            case 6:
                API = 15
                break;
            case 7:
                API = 16
                break;
            default:
                API = 1
                break;
        }

        if (this.state.page === 0) {
            Content.linkContent(this.state.persona.id, id, order, API)
                .then(result => {
                    console.log(result)
                    this.getPersona(true)
                })
        } else if (this.state.page === 1) {
            Stims.linkStim(this.state.persona.id, id, order, API)
                .then(result => {
                    console.log(result)
                    this.getPersona(true)
                })
        } else if (this.state.page === 2) {
            Content.linkContent(this.state.persona.id, id, order, 3)
                .then(result => {
                    console.log(result)
                    this.getPersona(true)
                })
        } else if (this.state.page === 3) {
            Content.linkContent(this.state.persona.id, id, order, 4)
                .then(result => {
                    console.log(result)
                    this.getPersona(true)
                })
        } else if (this.state.page === 5) {
            Content.linkContent(this.state.persona.id, id, order, 6)
                .then(result => {
                    console.log(result)
                    this.getPersona(true)
                })
        } else if (this.state.page === 6) {
            Stims.linkStim(this.state.persona.id, id, order, "7")
                .then(result => {
                    console.log(result)
                    this.getPersona(true)
                })
        } else {
            Stims.linkStim(this.state.persona.id, id, order, 5)
                .then(result => {
                    console.log(result)
                    this.getPersona(true)
                })
        }
    }

    edit = (id) => {
        this.setState({
            editID: id,
        }, () => {
            this.toggleModal()
        })
    }

    unlink = (id) => {
        let API
        switch (this.state.persona.type) {
            case 1:
                API = 1
                break;
            case 2:
                API = 6
                break;
            case 4:
                API = 12
                break;
            case 5:
                API = 13
                break;
            case 6:
                API = 15
                break;
            case 7:
                API = 16
                break;
            default:
                API = 1
                break;
        }

        if (this.state.page === 0) {
            Content.unlinkContent(this.state.persona.id, id, API)
                .then(result => {
                    console.log(result)
                    this.getPersona(true)
                })
        } else if (this.state.page === 1) {
            Stims.unlinkStim(this.state.persona.id, id, API)
                .then(result => {
                    console.log(result)
                    this.getPersona(true)
                })
        } else if (this.state.page === 2) {
            Content.unlinkContent(this.state.persona.id, id, 3)
                .then(result => {
                    console.log(result)
                    this.getPersona(true)
                })
        } else if (this.state.page === 3) {
            Content.unlinkContent(this.state.persona.id, id, 4)
                .then(result => {
                    console.log(result)
                    this.getPersona(true)
                })
        } else if (this.state.page === 5) {
            Content.unlinkContent(this.state.persona.id, id, 6)
                .then(result => {
                    console.log(result)
                    this.getPersona(true)
                })
        } else if (this.state.page === 6) {
            Stims.unlinkStim(this.state.persona.id, id, 7)
                .then(result => {
                    console.log(result)
                    this.getPersona(true)
                })
        } else {
            Stims.unlinkStim(this.state.persona.id, id, 5)
                .then(result => {
                    console.log(result)
                    this.getPersona(true)
                })
        }
    }

    onDragEnd(result) {
        if (!result.destination) {
            return;
        }

        let content = this.state.content;
        const [removed] = content.splice(result.source.index, 1);
        content.splice(result.destination.index, 0, removed);

        content.forEach( order => {
            let temp = content.indexOf(order)
            order.order = temp + 1
        })

        this.setState(  {
            content: content
        })
    }

    updateSearchTags = (event, newValue) => {
        this.setState({
            currentTags: newValue
        }, () => {
            this.searchContent()
        })
    }

    updateSearchText(event) {
        this.setState({
            currentSearch: event.target.value
        }, () => {
            this.searchContent()
        })
    }

    updateSearchType = (event) => {
        this.setState({
            currentType: event.target.value
        }, () => {
            this.searchContent()
        })
    }

    updateSearchType2 = (event) => {
        this.setState({
            currentType2: event.target.value
        }, () => {
            this.searchContent()
        })
    }

    updateSearchType3 = (event) => {
        this.setState({
            currentType3: event.target.value
        }, () => {
            this.searchContent()
        })
    }

    searchContent() {
        let results
        if (this.state.page === 0){
            results = this.state.allContent
        } else if (this.state.page === 1) {
            results = this.state.allStims
        } else if (this.state.page === 2) {
            results = this.state.allStories
        } else if (this.state.page === 3) {
            results = this.state.allWatch
        } else if (this.state.page === 4) {
            results = this.state.allWatchADs
        } else if (this.state.page === 5) {
            results = this.state.allReels
        } else if (this.state.page === 6) {
            results = this.state.allReelADs
        }

        if (this.state.currentSearch.length !== 0) {
            results = results.filter(obj => {return obj.title.toUpperCase().includes(this.state.currentSearch.toUpperCase())})
        }

        if (this.state.currentType2 === "new") {
            results = results.sort(function (a,b){
                return (b.id - a.id)
            })
        }

        if (this.state.currentType2 === "old") {
            results = results.sort(function (a,b){
                return (a.id - b.id)
            })
        }

        if (this.state.currentType3 === "sti") {
            results = results.filter(obj => {return obj.viewability_tracking})
        }

        if (this.state.currentType3 === "nsti") {
            results = results.filter(obj => {return !obj.viewability_tracking})
        }

        if (this.state.currentType === "vod") {
            results = results.filter(obj => {return obj.src.toUpperCase().includes("MP4")})
        }

        if (this.state.currentType === "pic") {
            results = results.filter(obj => {return ["PNG","JPEG","JPG","GIF","PHOTO","PHP"].some(pic => obj.src.toUpperCase().includes(pic))})
        }

        if (this.state.currentTags.length !== 0) {
            let link = []
            this.state.currentTags.forEach(item => {
                let temp = this.state.linkTags.filter(obj => {return obj.tag === item.id})
                temp.forEach(item2 => {
                    if (!link.includes(item2.content)) {
                        link.push(item2.content)
                    }
                })
            })
            results = results.filter(obj => {return link.includes(obj.id)})
        }

        this.setState({searchContent:results})
    }

    handleChange = (event) => {
        let temp = this.state.link;
        temp[event.target.name] = event.target.value;
        this.setState({link: temp})
    }

    handleFind = (newValue) => {
        let temp = this.state.link;
        temp["post_id"] = newValue.value;
        this.setState({link: temp})
    }

    handlePersonaChange = (event) => {
        let temp = this.state.persona;
        temp[event.target.name] = event.target.value;
        this.setState({persona: temp})
    }

    handleCheck = (event) => {
        let temp = this.state.persona;
        if (temp[event.target.name] === 1) {
            temp[event.target.name] = 0
        } else {
            temp[event.target.name] = 1
        }
        this.setState({profile: temp})
    }

    handleContentLock = (event) => {
        let temp = this.state.content
        if (temp[event.target.name].locked === 1) {
            temp[event.target.name].locked = 0
        } else {
            temp[event.target.name].locked = 1
        }
        console.log(temp)
        this.setState({content: temp})
    }

    handleAdLock = (event) => {
        let temp = this.state.stims
        if (temp[event.target.name].locked === 1) {
            temp[event.target.name].locked = 0
        } else {
            temp[event.target.name].locked = 1
        }
        this.setState({stims: temp})
    }

    handleContentOrder = (event) => {
        let temp = this.state.content
        temp[event.target.name].order = event.target.value;
        this.setState({content: temp})
    }

    handleAdOrder = (event) => {
        let temp = this.state.stims
        temp[event.target.name].order = event.target.value;
        this.setState({stims: temp})
    }

    handleStoryOrder = (event) => {
        let temp = this.state.stories
        temp[event.target.name].order = event.target.value;
        this.setState({stories: temp})
    }

    handleWatchOrder = (event) => {
        let temp = this.state.watch
        temp[event.target.name].order = event.target.value;
        this.setState({watch: temp})
    }

    handleWatchADOrder = (event) => {
        let temp = this.state.watchADs
        temp[event.target.name].order = event.target.value;
        this.setState({watchADs: temp})
    }

    handleReelOrder = (event) => {
        let temp = this.state.reels
        temp[event.target.name].order = event.target.value;
        this.setState({reels: temp})
    }

    handleReelADOrder = (event) => {
        let temp = this.state.reelADs
        temp[event.target.name].order = event.target.value;
        this.setState({reelADs: temp})
    }

    openView = () => {
        this.setState({
            openView: true,
        })
    }

    closeView = () =>{
        this.setState({
            openView: false,
        })
    }

    openReelView = () => {
        this.setState({
            openReelView: true,
        })
    }

    closeReelView = () =>{
        this.setState({
            openReelView: false,
        })
    }

    openVideoView = () => {
        this.setState({
            openVideoView: true,
        })
    }

    closeVideoView = () =>{
        this.setState({
            openVideoView: false,
        })
    }

    validate = () => {
        let persona = this.state.persona
        let errors = {}
        let valid = true
        if (!persona.title || persona.title.length < 1) {
            valid = false
            errors.title = "Title must exist"
        }
        if (escape(persona.title).length >= 1024) {
            valid = false
            errors.title = "Title too long"
        }

        if (!persona.name || persona.name.length < 1) {
            valid = false
            errors.name = "Name must exist"
        }
        if (escape(persona.name).length >= 50) {
            valid = false
            errors.title = "Name too long"
        }

        /* -- Not Needed
        if (!persona.pic || persona.pic.length < 1) {
            valid = false
            errors.pic = "Picture must exist"
        }
        */

        if (!persona.auto_move_time) {
            valid = false
            errors.auto_move_time = "Must Exist"
        } else if (isNaN(Number(persona.auto_move_time))) {
            valid = false
            errors.auto_move_time = "Must be a Number"
        }

        if (!persona.show_next_time) {
            valid = false
            errors.show_next_time = "Must Exist"
        } else if (isNaN(Number(persona.show_next_time))) {
            valid = false
            errors.show_next_time = "Must be a Number"
        }

        if (!persona.inactive_time) {
            valid = false
            errors.inactive_time = "Must Exist"
        } else if (isNaN(Number(persona.inactive_time))) {
            valid = false
            errors.inactive_time = "Must be a Number"
        }

        if (!persona.selecting_time) {
            valid = false
            errors.selecting_time = "Must Exist"
        } else if (isNaN(Number(persona.selecting_time))) {
            valid = false
            errors.selecting_time = "Must be a Number"
        }

        if (!persona.fail_time) {
            valid = false
            errors.fail_time = "Must Exist"
        } else if (isNaN(Number(persona.fail_time))) {
            valid = false
            errors.fail_time = "Must be a Number"
        }

        this.setState({errors: errors})
        return valid
    }

    savePersona(){
        this.props.enqueueSnackbar('Validating', {variant:"info"});
        if(this.validate()) {
            this.props.enqueueSnackbar('Saving', {variant:"info"});
            this.setState({
                posting: true,
            }, () => {
                const options = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
                    body: JSON.stringify({
                        'persona':this.state.persona,
                        'content':this.state.content,
                        'stims': this.state.stims,
                        'stories': this.state.stories,
                        'reels': this.state.reels,
                        'reelADs': this.state.reelADs,
                    })
                };

                cf.get(`${process.env.REACT_APP_API_POST}/db/updatePersona`, options)
                    .then((result) => {
                            console.log(result)
                            this.setState({
                                posting: false
                            }, () => {
                                if (!!result) {
                                    this.props.enqueueSnackbar('Saved', {variant:"success"});
                                    this.setState({
                                        content: this.state.content.sort(function (a, b) {return a.order - b.order;}),
                                        stims: this.state.stims.sort(function (a, b) {return a.order - b.order;}),
                                        stories: this.state.stories.sort(function (a, b) {return a.order - b.order;}),
                                        reels: this.state.reels.sort(function (a, b) {return a.order - b.order;}),
                                        reelADs: this.state.reelADs.sort(function (a, b) {return a.order - b.order;})
                                    })
                                } else {
                                    this.props.enqueueSnackbar('Save Failed - Please Try Again', {variant:"warning"});
                                }
                            });
                        },
                        (error) =>{
                            console.log(error)
                            this.setState({
                                posting: false
                            }, () => {
                                this.props.enqueueSnackbar('Save Failed - Please Contact an Admin', {variant:"error"});
                            });
                        }
                    )
            })
        } else {
            this.props.enqueueSnackbar('Validation Failed Please Check Your Inputs', {variant:"error"});
        }
    }

    componentDidMount() {
        this.getPersona(false)
    }

    render() {
        const { error, errors, isLoaded, openView, openReelView, openVideoView, id, editID, content, posting, openModal, stims, reels, reelADs, persona, currentFeed, currentType, currentType2, currentType3, allLanguageCodes, page, stories, watch, watchADs,open, openPrompts, tags, linkTags, currentPage, itemsPerPage, searchContent } = this.state
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const currentItems = searchContent.slice(indexOfFirstItem, indexOfLastItem)

        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return (
                <>
                    <Header/>
                    <div style={{width:"50%"}}>
                        <LinearProgress />
                    </div>
                </>
            )
        } else {
            const tagOptions = tags.map((option) => {
                const firstLetter = option.name[0].toUpperCase();
                return {
                    firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
                    ...option,
                };
            });
            // const _countryOptions = countries.filter(item => item.lang_id === persona.lang_id)
            return (
                <>
                    <Header />
                    <Modal open={openModal} onClose={this.toggleModal}>
                        <div className="edit-item-container">
                            {page === 0 && (
                                <>
                                    {persona.type === 1 && (
                                        <Edit
                                            id={editID}
                                            type={"content"}
                                            close={this.toggleModal}
                                        />
                                    )}
                                    {persona.type === 2 && (
                                        <Edit
                                            id={editID}
                                            type={"reel"}
                                            close={this.toggleModal}
                                        />
                                    )}
                                    {persona.type === 4 && (
                                        <GmailEdit
                                            id={editID}
                                            type={"gmail"}
                                            close={this.toggleModal}
                                        />
                                    )}
                                    {persona.type === 5 && (
                                        <GmailEdit
                                            id={editID}
                                            type={"music"}
                                            close={this.toggleModal}
                                        />
                                    )}
                                    {persona.type === 7 && (
                                        <Edit
                                            id={editID}
                                            type={"video"}
                                            close={this.toggleModal}
                                        />
                                    )}
                                </>
                            )}
                            {page === 1 && (
                                <>
                                    {persona.type === 1 && (
                                        <Edit
                                            id={editID}
                                            type={"stim"}
                                            close={this.toggleModal}
                                        />
                                    )}
                                    {persona.type === 2 && (
                                        <Edit
                                            id={editID}
                                            type={"reel_stim"}
                                            close={this.toggleModal}
                                        />
                                    )}
                                    {persona.type === 5 && (
                                        <Edit
                                            id={editID}
                                            type={"music_stim"}
                                            close={this.toggleModal}
                                        />
                                    )}
                                    {persona.type === 7 && (
                                        <Edit
                                            id={editID}
                                            type={"video_stim"}
                                            close={this.toggleModal}
                                        />
                                    )}
                                </>
                            )}
                            {page === 2 && (
                                <Edit
                                    id={editID}
                                    type={"story"}
                                    close={this.toggleModal}
                                />
                            )}
                            {page === 3 && (
                                <Edit
                                    id={editID}
                                    type={"watch"}
                                    close={this.toggleModal}
                                />
                            )}
                            {page === 4 && (
                                <Edit
                                    id={editID}
                                    type={"watch_stim"}
                                    close={this.toggleModal}
                                />
                            )}
                            {page === 5 && (
                                <Edit
                                    id={editID}
                                    type={"reel"}
                                    close={this.toggleModal}
                                />
                            )}
                            {page === 6 && (
                                <Edit
                                    id={editID}
                                    type={"reel_stim"}
                                    close={this.toggleModal}
                                />
                            )}
                        </div>
                    </Modal>
                    <div className="social">
                        <div className="top-selector">
                            <div className={page === 0 ? "items selected" : "items"} onClick={ () => this.setPage(0, false)}>Content</div>
                            {persona.type !== 5 && (
                                <div className={page === 1 ? "items selected" : "items"} onClick={ () => this.setPage(1, false)}>Promoted</div>
                            )}
                            {persona.type === 1 && (
                                <>
                                    <div className={page === 2 ? "items selected" : "items"} onClick={ () => this.setPage(2, false)}>Stories</div>
                                    <div className={page === 3 ? "items selected" : "items"} onClick={ () => this.setPage(3, false)}>Watch Content</div>
                                    <div className={page === 4 ? "items selected" : "items"} onClick={ () => this.setPage(4, false)}>Watch Promoted</div>
                                    <div className={page === 5 ? "items selected" : "items"} onClick={ () => this.setPage(5, false)}>Reel Content</div>
                                    <div className={page === 6 ? "items selected" : "items"} onClick={ () => this.setPage(6, false)}>Reel Promoted</div>
                                </>
                            )}
                        </div>
                        <div className="settings">
                            <TextField error={!!errors.title} helperText={errors.title} className="spacer" variant="outlined" label="Title" name="title"  value={persona.title} onChange={this.handlePersonaChange} />
                            <TextField error={!!errors.name} helperText={errors.name} className="spacer" variant="outlined" label="Name" name="name" value={persona.name} onChange={this.handlePersonaChange} />
                            <TextField error={!!errors.pic} helperText={errors.pic} className="spacer" variant="outlined" label="Picture" name="pic" value={persona.pic} onChange={this.handlePersonaChange} />
                            <TextField error={!!errors.pic} helperText={errors.pic} className="spacer" variant="outlined" label="Session Timer" name="session_timer" value={persona.session_timer} onChange={this.handlePersonaChange} />
                            <FormControl className="spacer" variant="outlined">
                                <InputLabel>Language Code</InputLabel>
                                <Select
                                    value={persona.language_code}
                                    onChange={this.handlePersonaChange}
                                    label="LanguageCode"
                                    name="language_code"
                                >
                                    {allLanguageCodes.map((item) => (
                                        <MenuItem value={item.id} key={item.id}>{item.label || item.code}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            {/* <FormControlLabel className="spacer"
                                              control={
                                                  <Switch
                                                      checked={persona.use_translation_engine}
                                                      onChange={this.handleCheck}
                                                      name="use_translation_engine"
                                                      color="primary"
                                                  />
                                              }
                                              label="Use Translation Engine"
                            />
                            <FormControl className="spacer" variant="outlined">
                                <InputLabel>Lang</InputLabel>
                                <Select
                                    value={persona.lang_id}
                                    onChange={this.handlePersonaChange}
                                    label="Lang"
                                    name="lang_id"
                                >
                                    {lang.map((item) => (
                                        <MenuItem value={item.id} key={item.id}>{item.language}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl className="spacer" variant="outlined">
                                <InputLabel>Country</InputLabel>
                                <Select
                                    value={persona.country_id}
                                    onChange={this.handlePersonaChange}
                                    label="Country"
                                    name="country_id"
                                >
                                    {countryOptions.map((item) => (
                                        <MenuItem value={item.id} key={item.id}>{item.country}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl> */}
                            <FormControl className="spacer" variant="outlined">
                                <InputLabel>Type</InputLabel>
                                <Select
                                    value={persona.type}
                                    onChange={this.handlePersonaChange}
                                    label="Type"
                                    name="type"
                                >
                                    <MenuItem value={1}>Default</MenuItem>
                                    <MenuItem value={2}>Reel</MenuItem>
                                    <MenuItem value={4}>Gmail</MenuItem>
                                    <MenuItem value={5}>Music</MenuItem>
                                    <MenuItem value={7}>Video</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl className="spacer" variant="outlined">
                                <InputLabel>Content Ordered</InputLabel>
                                <Select
                                    value={persona.content_ordered}
                                    onChange={this.handlePersonaChange}
                                    label="Content Ordered"
                                    name="content_ordered"
                                >
                                    <MenuItem value={1}>Ordered</MenuItem>
                                    <MenuItem value={2}>Random</MenuItem>
                                    <MenuItem value={3}>First Set</MenuItem>
                                    <MenuItem value={4}>First & Last</MenuItem>
                                    <MenuItem value={5}>First, Second & Last</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl className="spacer" variant="outlined">
                                <InputLabel>Stim Ordered</InputLabel>
                                <Select
                                    value={persona.stim_ordered}
                                    onChange={this.handlePersonaChange}
                                    label="Stim Ordered"
                                    name="stim_ordered"
                                >
                                    <MenuItem value={1}>Ordered</MenuItem>
                                    <MenuItem value={2}>Random</MenuItem>
                                    <MenuItem value={3}>First Set</MenuItem>
                                    <MenuItem value={4}>First & Last</MenuItem>
                                    <MenuItem value={5}>First, Second & Last</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl className="spacer" variant="outlined">
                                <InputLabel>Story Content Ordered</InputLabel>
                                <Select
                                    value={persona.story_content_ordered}
                                    onChange={this.handlePersonaChange}
                                    label="Story Content Ordered"
                                    name="story_content_ordered"
                                >
                                    <MenuItem value={1}>Ordered</MenuItem>
                                    <MenuItem value={2}>Random</MenuItem>
                                    <MenuItem value={3}>First Set</MenuItem>
                                    <MenuItem value={4}>First & Last</MenuItem>
                                    <MenuItem value={5}>First, Second & Last</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl className="spacer" variant="outlined">
                                <InputLabel>Story Stim Ordered</InputLabel>
                                <Select
                                    value={persona.story_stim_ordered}
                                    onChange={this.handlePersonaChange}
                                    label="Story Stim Ordered"
                                    name="story_stim_ordered"
                                >
                                    <MenuItem value={1}>Ordered</MenuItem>
                                    <MenuItem value={2}>Random</MenuItem>
                                    <MenuItem value={3}>First Set</MenuItem>
                                    <MenuItem value={4}>First & Last</MenuItem>
                                    <MenuItem value={5}>First, Second & Last</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl className="spacer" variant="outlined">
                                <InputLabel>Social Page</InputLabel>
                                <Select
                                    value={persona.feed_page}
                                    onChange={this.handlePersonaChange}
                                    label="Social Page"
                                    name="feed_page"
                                >
                                    <MenuItem value={0}>Feed</MenuItem>
                                    <MenuItem value={1}>Watch</MenuItem>
                                    <MenuItem value={2}>Reels</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControlLabel className="spacer"
                                              control={
                                                  <Switch
                                                      checked={persona.available_in_dashboard}
                                                      onChange={this.handleCheck}
                                                      name="available_in_dashboard"
                                                      color="primary"
                                                  />
                                              }
                                              label="Available In Dashboard"
                            />
                            <FormControlLabel className="spacer"
                                              control={
                                                  <Switch
                                                      checked={persona.master_persona}
                                                      onChange={this.handleCheck}
                                                      name="master_persona"
                                                      color="primary"
                                                  />
                                              }
                                              label="Master Persona"
                            />
                            <FormControlLabel className="spacer"
                                              control={
                                                  <Switch
                                                      checked={persona.prompts}
                                                      onChange={this.handleCheck}
                                                      name="prompts"
                                                      color="primary"
                                                  />
                                              }
                                              label="Prompts"
                            />
                            <FormControlLabel className="spacer"
                                              control={
                                                  <Switch
                                                      checked={persona.processed}
                                                      onChange={this.handleCheck}
                                                      name="processed"
                                                      color="primary"
                                                  />
                                              }
                                              label="Processed"
                            />
                            <FormControlLabel className="spacer"
                                              control={
                                                  <Switch
                                                      checked={persona.skippable_stims}
                                                      onChange={this.handleCheck}
                                                      name="skippable_stims"
                                                      color="primary"
                                                  />
                                              }
                                              label="Skippable Stims"
                            />
                            <div className="buttons">
                                <Button style={{height: "40px"}} disabled={!persona.prompts} variant="contained" color="primary" onClick={() => this.openPrompts()}>Prompts</Button>
                                {persona.type === 1 ? <Button style={{height: "40px"}} onClick={this.openView} variant="contained" color="primary">View</Button> : ""}
                                {persona.type === 2 ? <Button style={{height: "40px"}} onClick={this.openReelView} variant="contained" color="primary">View</Button> : ""}
                                {persona.type === 4 ? <Link to={`/gmail/view/${persona.uuid}`} target="_blank"><Button style={{height: "40px"}} variant="contained" color="primary">View</Button></Link> : ""}
                                {persona.type === 5 ? <Link to={`/music/view/${persona.uuid}`} target="_blank"><Button style={{height: "40px"}} variant="contained" color="primary">View</Button></Link> : ""}
                                {persona.type === 7 ? <Button style={{height: "40px"}} onClick={this.openVideoView} variant="contained" color="primary">View</Button> : ""}
                            </div>
                        </div>
                        <div className="box persona-edit">
                            {page === 0 ? (
                                <ContentShow
                                    handleOrder={this.handleContentOrder}
                                    unlink={this.unlink}
                                    handleLock={this.handleContentLock}
                                    edit={this.edit}
                                    id={id}
                                    content={content}
                                />
                            ) : ""}
                            {page === 1 ? (
                                <ContentShow
                                    handleOrder={this.handleAdOrder}
                                    unlink={this.unlink}
                                    handleLock={this.handleAdLock}
                                    edit={this.edit}
                                    content={stims}
                                />
                            ) : ""}
                            {page === 2 ? (
                                <Story
                                    handleStoryOrder={this.handleStoryOrder}
                                    unlink={this.unlink}
                                    edit={this.edit}
                                    stories={stories}
                                />
                            ) : ""}
                            {page === 3 ? (
                                <Watch
                                    handleWatchOrder={this.handleWatchOrder}
                                    unlink={this.unlink}
                                    edit={this.edit}
                                    watch={watch}
                                />
                            ) : ""}
                            {page === 4 ? (
                                <WatchAD
                                    handleWatchADOrder={this.handleWatchADOrder}
                                    unlink={this.unlink}
                                    edit={this.edit}
                                    watchADs={watchADs}
                                />
                            ) : ""}
                            {page === 5 ? (
                                <ContentShow
                                    handleOrder={this.handleReelOrder}
                                    unlink={this.unlink}
                                    handleLock={this.handleContentLock}
                                    edit={this.edit}
                                    id={id}
                                    content={reels}
                                />
                            ) : ""}
                            {page === 6 ? (
                                <ContentShow
                                    handleOrder={this.handleReelADOrder}
                                    unlink={this.unlink}
                                    handleLock={this.handleAdLock}
                                    edit={this.edit}
                                    content={reelADs}
                                />
                            ) : ""}
                        </div>
                        <div className="links">
                            <Button variant="contained" color="primary" disabled={posting} onClick={() => this.open()} className="spacer">Link Content</Button>
                            <Link to="/personas"><Button disabled={posting} variant="contained" color="primary" className="spacer">Back</Button></Link>
                            <Button  disabled={posting} style={{height: "40px"}} variant="contained" color="primary" onClick={() => this.savePersona()}>Save</Button>
                        </div>
                        <div className={(open ? 'user-box' : 'user-box hide')}>
                            <div className="link-container">
                                <div style={{display: "flex", padding: "20px"}}>
                                    <TextField
                                        label="Search"
                                        variant="outlined"
                                        style={{marginRight: "10px"}}
                                        onChange={(e) => this.updateSearchText(e)}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon/>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />

                                    <Autocomplete
                                        multiple
                                        renderInput={(params) => <TextField style={{width: "250px"}} variant="outlined" {...params} label="Tags" />}
                                        options={tagOptions.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                                        groupBy={(option) => option.firstLetter}
                                        getOptionLabel={(option) => option.name}
                                        onChange={this.updateSearchTags}
                                    />
                                    <ToggleButtonGroup
                                        color="primary"
                                        value={currentType2}
                                        exclusive
                                        onChange={this.updateSearchType2}
                                        style={{marginLeft: "10px"}}
                                    >
                                        <ToggleButton value="new">New</ToggleButton>
                                        <ToggleButton value="old">Old</ToggleButton>
                                    </ToggleButtonGroup>
                                    <ToggleButtonGroup
                                        color="primary"
                                        value={currentType}
                                        exclusive
                                        onChange={this.updateSearchType}
                                        style={{marginLeft: "10px"}}
                                    >
                                        <ToggleButton value="all">All</ToggleButton>
                                        <ToggleButton value="vod">Vod</ToggleButton>
                                        <ToggleButton value="pic">Pic</ToggleButton>
                                    </ToggleButtonGroup>
                                    <ToggleButtonGroup
                                        color="primary"
                                        value={currentType3}
                                        exclusive
                                        onChange={this.updateSearchType3}
                                        style={{marginLeft: "10px"}}
                                    >
                                        <ToggleButton value="all">All</ToggleButton>
                                        <ToggleButton value="sti">Stims</ToggleButton>
                                        <ToggleButton value="nsti">Non-Stims</ToggleButton>
                                    </ToggleButtonGroup>
                                </div>

                                <div className="profiles-container" style={{justifyContent: "left", marginLeft: 0, width: "100%"}}>
                                    {currentItems.map((item) => (
                                        <Card key={item.id}
                                              style={{width: "340px", marginBottom: "20px", backgroundColor: "#424242", marginLeft: "17px", position: "relative"}}>
                                            {item.status === 4 && (
                                                <div className="src-warning">
                                                    <Tooltip title={"Source Error"}><GppMaybeIcon color="error" /></Tooltip>
                                                </div>
                                            )}
                                            <CardActionArea>
                                                {item.src ? (
                                                    <CardMedia
                                                        className={currentFeed.filter(o => {return o.id === item.id })[0] ? "onFeed" : ""}
                                                        component={item.src.split(".")[item.src.split(".").length - 1] === "mp4" ? "video" : "" }
                                                        style={{height: "140px"}}
                                                        image={item.src}
                                                        title={item.title}
                                                    />
                                                    ) : (
                                                    <div style={{color: "#ffffff85", fontWeight: 700, fontSize: "30px", height: "140px"}}>
                                                        {currentFeed.filter(o => {return o.id === item.id })[0] ? "IN FEED" : "WHERE'S THE LAMB SAUCE"}
                                                    </div>
                                                    ) }
                                                <CardContent style={{backgroundColor: "#515151", height: "80px"}}>
                                                    <div style={{
                                                        color: "rgba(255, 255, 255, 0.7)",
                                                        fontSize: "18px"
                                                    }}>{item.title}</div>
                                                </CardContent>
                                            </CardActionArea>
                                            <CardActions style={{backgroundColor: "#515151"}}>
                                                <Tags tags={tags} lTags={linkTags} item={item.id}/>
                                            </CardActions>
                                            <CardActions>
                                                <Button disabled={currentFeed.filter(o => {return o.id === item.id })[0]} size="small" variant="contained" color="secondary" onClick={() => this.link(item.id)}>
                                                    ADD
                                                </Button>
                                            </CardActions>
                                        </Card>
                                    ))}
                                </div>
                                <div style={{padding: "5px", alignSelf: "center"}}>
                                    <Pagination count={Math.ceil(searchContent.length / itemsPerPage)} page={currentPage}
                                                variant="outlined" onChange={(event, page) => this.setPages(event, page)}/>
                                    <div style={{display: "flex", justifyContent: "space-evenly"}}>
                                        <Button variant="contained" color="primary"
                                                onClick={(e) => this.close(e)}>Close</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={(openPrompts ? 'prompts-container' : 'promptsContainer hide')}>
                            <div className="prompts">
                                <div className="prompt-items">
                                    <div className="prompt-item" id="auto-move">
                                        <FormControlLabel className="spacer"
                                                          control={
                                                              <Switch
                                                                  checked={persona.auto_move}
                                                                  onChange={this.handleCheck}
                                                                  name="auto_move"
                                                                  color="primary"
                                                              />
                                                          } label="Auto Move"
                                        />
                                        <TextField error={!!errors.auto_move_time} helperText={errors.auto_move_time} className="spacer" variant="outlined" label="Auto Move Time" name="auto_move_time" value={persona.auto_move_time} onChange={this.handlePersonaChange} />
                                        <FormControl className="spacer" variant="outlined">
                                            <InputLabel>Auto Move Type</InputLabel>
                                            <Select
                                                value={persona.auto_move_type}
                                                onChange={this.handlePersonaChange}
                                                label="Auto Move Type"
                                                name="auto_move_type"
                                            >
                                                <MenuItem value={0}>Page Start</MenuItem>
                                                <MenuItem value={1}>Content Start</MenuItem>
                                                <MenuItem value={2}>AD Start</MenuItem>
                                                <MenuItem value={3}>AD End</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="prompt-item" id="show-next">
                                        <FormControlLabel className="spacer"
                                                          control={
                                                              <Switch
                                                                  checked={persona.show_next}
                                                                  onChange={this.handleCheck}
                                                                  name="show_next"
                                                                  color="primary"
                                                              />
                                                          } label="Show Next"
                                        />
                                        <TextField error={!!errors.show_next_time} helperText={errors.show_next_time} className="spacer" variant="outlined" label="Show Next Time" name="show_next_time" value={persona.show_next_time} onChange={this.handlePersonaChange} />
                                        <FormControl className="spacer" variant="outlined">
                                            <InputLabel>Show Next Type</InputLabel>
                                            <Select
                                                value={persona.show_next_type}
                                                onChange={this.handlePersonaChange}
                                                label="Show Next Type"
                                                name="show_next_type"
                                            >
                                                <MenuItem value={0}>Page Start</MenuItem>
                                                <MenuItem value={1}>Content Start</MenuItem>
                                                <MenuItem value={2}>AD Start</MenuItem>
                                                <MenuItem value={3}>AD End</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="prompt-item" id="inactive">
                                        <FormControlLabel className="spacer"
                                                          control={
                                                              <Switch
                                                                  checked={persona.inactive}
                                                                  onChange={this.handleCheck}
                                                                  name="inactive"
                                                                  color="primary"
                                                              />
                                                          } label="Inactive"
                                        />
                                        <FormControlLabel className="spacer"
                                                          control={
                                                              <Switch
                                                                  checked={persona.inactive_repeat}
                                                                  onChange={this.handleCheck}
                                                                  name="inactive_repeat"
                                                                  color="primary"
                                                              />
                                                          } label="Inactive Repeat"
                                        />
                                        <TextField error={!!errors.inactive_time} helperText={errors.inactive_time} className="spacer" variant="outlined" label="Inactive Time" name="inactive_time" value={persona.inactive_time} onChange={this.handlePersonaChange} />
                                    </div>
                                    {persona.type === 2 ? "" : (
                                        <div className="prompt-item" id="noselect">
                                            <FormControlLabel className="spacer"
                                                              control={
                                                                  <Switch
                                                                      checked={persona.selecting}
                                                                      onChange={this.handleCheck}
                                                                      name="selecting"
                                                                      color="primary"
                                                                  />
                                                              } label="No Select"
                                            />
                                            <FormControlLabel className="spacer"
                                                              control={
                                                                  <Switch
                                                                      checked={persona.selecting_repeat}
                                                                      onChange={this.handleCheck}
                                                                      name="selecting_repeat"
                                                                      color="primary"
                                                                  />
                                                              } label="No Select Repeat"
                                            />
                                            <TextField error={!!errors.selecting_time} helperText={errors.selecting_time} className="spacer" variant="outlined" label="No Select Time" name="selecting_time" value={persona.selecting_time} onChange={this.handlePersonaChange} />
                                        </div>
                                    )}
                                    <div className="prompt-item" id="page-fail">
                                        <FormControlLabel className="spacer"
                                                          control={
                                                              <Switch
                                                                  checked={persona.fail}
                                                                  onChange={this.handleCheck}
                                                                  name="fail"
                                                                  color="primary"
                                                              />
                                                          } label="Page Fail"
                                        />
                                        <TextField error={!!errors.fail_time} helperText={errors.fail_time} className="spacer" variant="outlined" label="Page Fail Time" name="fail_time" value={persona.fail_time} onChange={this.handlePersonaChange} />

                                    </div>
                                    <div className="prompt-item" id="sliders">
                                        <FormControlLabel className="spacer"
                                                          control={
                                                              <Switch
                                                                  checked={persona.story_prompt}
                                                                  onChange={this.handleCheck}
                                                                  name="story_prompt"
                                                                  color="primary"
                                                              />
                                                          } label="Story Prompt"
                                        />
                                        <FormControlLabel className="spacer"
                                                          control={
                                                              <Switch
                                                                  checked={persona.watch_prompt}
                                                                  onChange={this.handleCheck}
                                                                  name="watch_prompt"
                                                                  color="primary"
                                                              />
                                                          } label="Watch Prompt"
                                        />
                                        <FormControlLabel className="spacer"
                                                          control={
                                                              <Switch
                                                                  checked={persona.reel_prompt}
                                                                  onChange={this.handleCheck}
                                                                  name="reel_prompt"
                                                                  color="primary"
                                                              />
                                                          } label="Reel Prompt"
                                        />
                                        <FormControlLabel className="spacer"
                                                          control={
                                                              <Switch
                                                                  checked={persona.feed_prompt}
                                                                  onChange={this.handleCheck}
                                                                  name="feed_prompt"
                                                                  color="primary"
                                                              />
                                                          } label="Feed Prompt"
                                        />
                                        <FormControlLabel className="spacer"
                                                          control={
                                                              <Switch
                                                                  checked={persona.force_feed}
                                                                  onChange={this.handleCheck}
                                                                  name="force_feed"
                                                                  color="primary"
                                                              />
                                                          } label="Force Feed"
                                        />
                                    </div>
                                </div>
                                <Button size="small" variant="contained" color="primary" onClick={(e) => this.close(e)}>Close</Button>
                            </div>
                        </div>
                    </div>
                    <div className={(openView ? 'user-box' : 'user-box hide')}>
                        <div className="user-container">
                            <Link to={`/facebook/view/${persona.uuid}`} target="_blank"><Button size="small" variant="contained" color="secondary">Facebook</Button></Link>
                            <Link to={`/instagram/view/${persona.uuid}`} target="_blank"><Button size="small" variant="contained" color="secondary">Instagram</Button></Link>
                            <Link to={`/pinterest/view/${persona.uuid}`} target="_blank"><Button size="small" variant="contained" color="secondary">Pinterest</Button></Link>
                            <Link to={`/vk/view/${persona.uuid}`} target="_blank"><Button size="small" variant="contained" color="secondary">VK</Button></Link>
                            <Link to={`/twitter/view/${persona.uuid}`} target="_blank"><Button size="small" variant="contained" color="secondary">Twitter</Button></Link>
                            <Link to={`/xiao/view/${persona.uuid}`} target="_blank"><Button size="small" variant="contained" color="secondary">Xiao</Button></Link>
                            <Button size="small" style={{marginTop:"10px"}} variant="contained" color="primary" onClick={e => this.closeView(e)}>Close</Button>
                        </div>
                    </div>
                    <div className={(openReelView ? 'user-box' : 'user-box hide')}>
                        <div className="user-container">
                            <Link to={`/tiktok/view/${persona.uuid}`} target="_blank"><Button size="small" variant="contained" color="secondary">TikTok</Button></Link>
                            <Link to={`/snapchat/view/${persona.uuid}`} target="_blank"><Button size="small" variant="contained" color="secondary">Snapchat</Button></Link>
                            <Link to={`/douyin/view/${persona.uuid}`} target="_blank"><Button size="small" variant="contained" color="secondary">Douyin</Button></Link>
                            <Button size="small" style={{marginTop:"10px"}} variant="contained" color="primary" onClick={e => this.closeReelView(e)}>Close</Button>
                        </div>
                    </div>
                    <div className={(openVideoView ? 'user-box' : 'user-box hide')}>
                        <div className="user-container">
                            <Link to={`/video/view/${persona.uuid}`} target="_blank"><Button size="small" variant="contained" color="secondary">Video</Button></Link>
                            <Link to={`/videoland/view/${persona.uuid}`} target="_blank"><Button size="small" variant="contained" color="secondary">Video Land</Button></Link>
                            <Link to={`/itvx/view/${persona.uuid}`} target="_blank"><Button size="small" variant="contained" color="secondary">ITVX</Button></Link>
                            <Link to={`/tencent/view/${persona.uuid}`} target="_blank"><Button size="small" variant="contained" color="secondary">Tencent</Button></Link>
                            <Link to={`/iq/view/${persona.uuid}`} target="_blank"><Button size="small" variant="contained" color="secondary">IQIYI</Button></Link>
                            <Button size="small" style={{marginTop:"10px"}} variant="contained" color="primary" onClick={e => this.closeVideoView(e)}>Close</Button>
                        </div>
                    </div>
                </>
            );
        }
    }
}

export default withSnackbar(PersonaEdit);
